<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-sm-12">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Affiliates</h3>
              <form @submit.prevent="searchArena()">
                <div class="mb-3">
                  <label for="arenaName" class="form-label">Search</label>
                  <input
                    type="text"
                    class="form-control"
                    id="arenaName"
                    placeholder="Enter Arena Name"
                    v-model="searchTerm"
                    @blur="markAsDirty()"
                  />
                  <span class="error" v-if="errorSearch && !searchTerm">Search is required</span>
                </div>
                <div class="submit-wrapper">
                  <button type="submit" class="btn btn-primary" :disabled="isDisabled">                    
                    <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    <span v-else>Find</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="card affiliate-results" v-if="isResultFound">
            <div class="card-body">
              <h3 class="card-title">Result</h3>
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Address</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Website</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="arenas.length">
                      <tr v-for="data in arenas" :key="data.id">
                        <td>{{ data.name }}</td>
                        <td><a :href="`http://maps.google.com/?q=${data.address}`" target="_blank">{{ data.address }}</a></td>
                        <td><a :href="`tel:${data.phone}`">{{ data.phone }}</a></td>
                        <td><a :href="`mailto:${data.email}`">{{ data.email }}</a></td>
                        <td><a :href="data.website" target="_blank">{{ data.website }}</a></td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" class="text-center">
                          No arena(s) found.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "SearchArena",
  data() {
    return {
      arenas: [],
      searchTerm: '',
      baseURL: process.env.VUE_APP_BASE_URL,
      errorSearch: false,
      isDisabled: false,
      isResultFound: false
    }
  },
  methods: {
    markAsDirty() {
      this.errorSearch = true;
      if (this.searchTerm.trim() !== '') {
        this.errorSearch = false;
      }
    },
    searchArena() {
      this.errorSearch = true;
      this.isResultFound = false;
      if (this.searchTerm.trim() !== '') {
        this.isDisabled = true;
        fetch(`${this.baseURL}arenas?name_like=${this.searchTerm}`)
          .then(response => response.json())
          .then(data => {
            this.isDisabled = false;
            // this.arenas = data.filter((arena) => arena.name.toUpperCase().includes(this.searchTerm.toUpperCase().trim()));
            this.arenas = data;
            this.isResultFound = true;
          });
        this.errorSearch = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.affiliate-results {
  margin-top: 50px;
}
</style>